import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import { Link, graphql } from "gatsby"
// import { connect } from 'react-redux'
//
// import { toggleDarkMode } from "../state/app"

export default ({data}) => (
  <Layout current={"home"}>
    <Header headerText={"Hello Gatsby!"} />
    <p>What a world.</p>

    {/*<button*/}
    {/*  style={isDarkMode ? { background: 'black', color: 'white' } : null}*/}
    {/*  onClick={() => dispatch(toggleDarkMode(!isDarkMode))}>Dark Mode {isDarkMode ? 'on' : 'off'}*/}
    {/*</button>*/}

    <img src="https://source.unsplash.com/random/400x200" alt="" />
    <div>
      <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}>
          <Link to={node.fields.slug}>
            <h3>
              {node.frontmatter.title} <span> - {node.frontmatter.date}</span>
            </h3>
            <p>{node.excerpt}</p>
          </Link>
        </div>
      ))}
    </div>
  </Layout>
)

// export default connect(state => ({
//   isDarkMode: state.app.isDarkMode
// }), null)(IndexPage)

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
